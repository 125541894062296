<script lang="ts" setup>
defineOptions({
  inheritAttrs: false
})

const {
  transition = 'wrapper-transition'
} = defineProps<{
  transition?: string

  /** Disables view popping when clicked on the overlay wrapper */
  disablePop?: boolean
}>()

const isActive = defineModel({
  default: false
})

// Functions
const show = (state = true) => isActive.value = state
const hide = () => show(false)

defineExpose({ show, hide })
</script>

<template>
  <slot name="cta" v-bind="{ show }" />

  <Teleport to="body">
    <Transition :name="transition" mode="out-in" appear>
      <div v-if="isActive" v-bind="$attrs" class="wrapper" @click.self="disablePop || hide()">
        <slot v-bind="{ show, hide }" />
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="sass">
.wrapper
  @apply fixed inset-0 z-999999 overflow-y-auto contain-strict

  // Animation direction
  --translate: translateX(50px)

  &[animate-from="bottom"]
    --translate: translateY(50px)

.wrapper-transition
  &-enter-active, &-leave-active
    overflow: hidden
    transition: all 250ms ease-in-out
    transition-property: opacity, transform

  &-enter-from, &-leave-to
    opacity: 0
    transform: var(--translate)
</style>
